import Vue from 'vue'
import VueRouter from 'vue-router'
import XHR from './xhr'
import oStore from './store'
import isMobile from './mobile'

Vue.use(VueRouter)

const oRouter = new VueRouter({
	mode: 'history',
	scrollBehavior( oTo, oFrom, oSavedPosition ) {
		if ( oSavedPosition ) {
			// If a saved position is present then this is a transition within
			// an existing stack triggered by browser back or forward buttons.
			// Preserve that position.

			return oSavedPosition;
		}
		else {
			// Otherwise override default router behavior and force scrolling
			// to the top of the window for route navigations to cope with iOS
			// preserving position between any views.

			return {x: 0, y: 0};
		}
	},
	routes: [
		{
			name: 'login',
			path: '/(-/login)?',
			component: () => import('./views/login.vue' /* webpackChunkName: "login" */),
			meta: {noAuth: true}
		},
		{
			name: 'forgot',
			path: '/-/login/forgot',
			component: () => import('./views/login/forgot.vue' /* webpackChunkName: "login-forgot" */),
			meta: {noAuth: true}
		},
		{
			name: 'reset',
			path: '/-/login/reset',
			component: () => import('./views/login/reset.vue' /* webpackChunkName: "login-reset" */),
			meta: {noAuth: true}
		},
		{
			// Legacy desktop UI. If this code is being served for it then
			// login is required.
			name: 'desktop',
			path: '/(desktop|shared-content)/*',
			component: () => import('./views/login.vue' /* webpackChunkName: "login" */),
			meta: {noAuth: true}
		},
		{
			path: '/:site/',
			component: () => import('./views/app.vue' /* webpackChunkName: "app" */),
			children: [
				{
					path: '',
					component: () => import('./views/app/home.vue' /* webpackChunkName: "home" */)
				},
				{
					path: 'news',
					component: () => import('./views/app/news.vue' /* webpackChunkName: "news" */)
				},
				{
					path: 'sites/:page?',
					component: () => import('./views/app/sites.vue' /* webpackChunkName: "sites" */),
					props: true
				},
				{
					path: '*',
					component: () => import('./view.vue' /* webpackChunkName: "view" */)
				}
			]
		}
	]
})

oRouter.beforeEach(function( oTo, oFrom, next ) {
	if ( oStore.state.user ) {
		if ( oTo.name == 'login' ) {
			// Already logged-in user dropping into slash.

			XHR.json('/api/-/login/info')
				.then(oResp => {
					if ( ! oResp.logged_in ) {
						next();
					}
					else if ( location.hash ) {
						window.location = '/desktop/' + location.hash;
					}
					else if ( oResp.site ) {
						if ( isMobile() ) {
							window.location = '/'+ encodeURIComponent(oResp.site)
								+'/';
						}
						else {
							window.location = '/desktop/#/'
								+ encodeURIComponent(oResp.site) +'/';
						}
					}
					else {
						window.location = '/desktop/';
					}
				})
				.catch(oErr => {
					window.location = '/desktop/' + location.hash;
				});

			return;
		}
	}
	else if ( ! oTo.meta.noAuth ) {
		// User not logged in and not in a login-exempt view. Redirect to login
		// view with a redirect URL that will be followed upon login.

		next({
			name: 'login',
			query: {then: oTo.fullPath}
		});

		return;
	}

	// Anything else proceeds as normal.

	next();
})

export default oRouter
